import type { JSX } from 'react';

import { Link } from '@change-corgi/core/react/router';
import { Avatar } from '@change-corgi/design-system/components/media';
import { Text } from '@change-corgi/design-system/typography';

import type { DecisionMaker } from 'src/app/pages/petition/details/shared/types';

import { useLoadedDecisionMakerListItem } from '../../../hooks/decisionMakers/useLoadedDecisionMakerListItem';
import type { AllDmResponses } from '../hooks/useDecisionMakers';

import { DecisionMakerListItem } from './DecisionMakerListItem';

type Props = {
	decisionMaker: DecisionMaker;
	allDmResponses: AllDmResponses;
};

export function LoadedDecisionMakerListItem({ decisionMaker, allDmResponses }: Props): JSX.Element {
	const {
		actions: { handleDmProfileLinkClick },
	} = useLoadedDecisionMakerListItem();
	const photoUrl = decisionMaker.photo?.userMedium.processing ? undefined : decisionMaker.photo?.userMedium.url;

	return (
		<DecisionMakerListItem
			photoContent={
				<Avatar variant="subtle" data-testid="dm-image" src={photoUrl} mr={10} size={50} alt="" loading="lazy" />
			}
			decisionMaker={decisionMaker}
			allDmResponses={allDmResponses}
		>
			{decisionMaker.publiclyVisible ? (
				<Link
					sx={{ fontWeight: 'bold' }}
					data-testid="dm-link"
					to={`/decision-makers/${decisionMaker.slug || decisionMaker.id}?source_location=petition_details`}
					onClick={() => handleDmProfileLinkClick(decisionMaker.id)}
				>
					<Text size="large" fontWeight="semibold" ellipsis>
						{decisionMaker.displayName}
					</Text>
				</Link>
			) : (
				<Text size="large" fontWeight="semibold" ellipsis>
					{decisionMaker.displayName}
				</Text>
			)}

			{decisionMaker.title && (
				<Text size="small" ellipsis>
					{decisionMaker.title}
				</Text>
			)}
		</DecisionMakerListItem>
	);
}
