import { useState } from 'react';
import type { JSX } from 'react';

import { useKnownAvailableParams } from '@change-corgi/core/react/router';

import type { PrefetchedDataProps } from 'src/shared/prefetch';
import { withPrefetchedData } from 'src/shared/prefetch';

import type { PetitionHeadlineBanditData } from 'src/app/pages/petition/details/shared/types';
import type { PetitionTabProps } from 'src/app/pages/petition/shared/tabTypes';

import { ShareHeadlineProvider, useDefaultShareHeadline, useRouteGuestSharer } from '../hooks/shareHeadline';

import { getFacebookBanditVariantText } from './api';
import { CommonPetitionDetails } from './CommonPetitionDetails';

type PrefetchedData = Readonly<{
	variant?: PetitionHeadlineBanditData;
}>;

export const PetitionDetailsFacebookBanditGuest = withPrefetchedData(
	({ userInfoState, prefetchedData }: PetitionTabProps & PrefetchedDataProps<PrefetchedData>): JSX.Element => {
		// this allows us to conserve the sharer after first render
		const [variant] = useState<PetitionHeadlineBanditData | undefined>(prefetchedData?.variant);
		const { slug } = useKnownAvailableParams<{ slug: string; sharerDisplayName: string; sharerLocation: string }>();

		const { title, description } = useDefaultShareHeadline();
		const sharer = useRouteGuestSharer();

		if (!sharer) {
			return <CommonPetitionDetails slug={slug} userInfoState={userInfoState} />;
		}

		if (!variant) {
			return (
				<ShareHeadlineProvider sharer={sharer} title={title} description={description}>
					<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
				</ShareHeadlineProvider>
			);
		}

		return (
			<ShareHeadlineProvider sharer={sharer} title={variant.headline} description={variant.description}>
				<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
			</ShareHeadlineProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [CommonPetitionDetails],
		prefetchData: async (context) => {
			const {
				params: { slug, experimentVariantName },
			} = context;
			if (!experimentVariantName) return {};
			try {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				return { variant: await getFacebookBanditVariantText(slug!, experimentVariantName, context.utilityContext) };
			} catch (e) {
				return {};
			}
		},
	},
);
