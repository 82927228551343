import { useState } from 'react';
import type { JSX } from 'react';

import { useKnownAvailableParams } from '@change-corgi/core/react/router';

import type { PrefetchedDataProps } from 'src/shared/prefetch';
import { withPrefetchedData } from 'src/shared/prefetch';

import type {
	PetitionHeadlineBanditData,
	PetitionMitaAlternateImageData,
	Sharer,
} from 'src/app/pages/petition/details/shared/types';
import type { PetitionTabProps } from 'src/app/pages/petition/shared/tabTypes';

import { MitaProvider } from '../hooks/mita';
import { ShareHeadlineProvider, useDefaultShareHeadline } from '../hooks/shareHeadline';

import { getFacebookBanditVariantText, getMitaAlternateImage, getSharer } from './api';
import { CommonPetitionDetails } from './CommonPetitionDetails';

type PrefetchedData = Readonly<{
	sharer?: Sharer;
	mita?: PetitionMitaAlternateImageData;
	variant?: PetitionHeadlineBanditData;
}>;

export const PetitionDetailsFacebookBandit = withPrefetchedData(
	({ userInfoState, prefetchedData }: PetitionTabProps & PrefetchedDataProps<PrefetchedData>): JSX.Element => {
		// this allows us to conserve the sharer after first render
		const [sharer] = useState<Sharer | undefined>(prefetchedData?.sharer);
		const [variant] = useState<PetitionHeadlineBanditData | undefined>(prefetchedData?.variant);
		const [mita] = useState<PetitionMitaAlternateImageData | undefined>(prefetchedData?.mita);
		const { slug } = useKnownAvailableParams<{ slug: string; sharerDisplayName: string; sharerLocation: string }>();

		const { title, description } = useDefaultShareHeadline();

		if (!sharer) {
			if (mita) {
				return (
					<MitaProvider image={mita}>
						<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
					</MitaProvider>
				);
			}
			return <CommonPetitionDetails slug={slug} userInfoState={userInfoState} />;
		}

		if (!variant) {
			if (mita) {
				return (
					<MitaProvider image={mita}>
						<ShareHeadlineProvider sharer={sharer} title={title} description={description}>
							<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
						</ShareHeadlineProvider>
					</MitaProvider>
				);
			}
			return (
				<ShareHeadlineProvider sharer={sharer} title={title} description={description}>
					<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
				</ShareHeadlineProvider>
			);
		}

		if (mita) {
			return (
				<MitaProvider image={mita}>
					<ShareHeadlineProvider sharer={sharer} title={variant.headline} description={variant.description}>
						<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
					</ShareHeadlineProvider>
				</MitaProvider>
			);
		}
		return (
			<ShareHeadlineProvider sharer={sharer} title={variant.headline} description={variant.description}>
				<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
			</ShareHeadlineProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [CommonPetitionDetails],
		prefetchData: async (context) => {
			const {
				params: { slug, experimentName, experimentVariantName, sharerUserId },
			} = context;

			if (!experimentVariantName || !sharerUserId) return {};
			return {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				variant: await getFacebookBanditVariantText(slug!, experimentVariantName, context.utilityContext).catch(
					() => undefined,
				),
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				mita: await getMitaAlternateImage(slug!, experimentName!, experimentVariantName, context.utilityContext).catch(
					() => undefined,
				),
				sharer: await getSharer(sharerUserId, context.utilityContext).catch(() => undefined),
			};
		},
	},
);
