import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { FakeLink } from '@change-corgi/design-system/components/actions';
import { Separator } from '@change-corgi/design-system/components/content';
import { Box } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

import { usePetitionDetailsPageData } from 'src/app/pages/petition/details/pageContext';

import { useDmStatus } from '../../hooks/decisionMakers/useDmStatus';
import { ScrollTrackingWaypointContainer } from '../ScrollTrackingWaypoint';

import { LoadedDecisionMakerListItem } from './components/LoadedDecisionMakerListItem';
import { useDecisionMakers } from './hooks/useDecisionMakers';

export function DecisionMakersContainer(): JSX.Element | null {
	const {
		data: { displayed, displayedDMs, displayShowAllButton, allDmResponses, shouldTrackDmEmailCtaView },
		actions: { openModal },
	} = useDecisionMakers();
	const {
		data: {
			petition: { id },
		},
	} = usePetitionDetailsPageData();
	const {
		data: { showDmStatus },
	} = useDmStatus();

	if (!displayed) return null;

	return (
		<>
			<Box px={16} pb={32}>
				<Heading as="h2" size={['h4', 'h3']} mb={24} id="decision-makers-heading">
					<Translate value="corgi.petition.details.decision_makers.title" />
				</Heading>

				{showDmStatus && (
					<ScrollTrackingWaypointContainer name="petition_page_dm_status_view" trackingData={{ petition_id: id }} />
				)}
				{shouldTrackDmEmailCtaView && (
					<ScrollTrackingWaypointContainer name="petition_page_email_dm_view" trackingData={{ petition_id: id }} />
				)}

				{/* using role due to https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html */}
				{/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
				<ul role="list">
					{displayedDMs.map((decisionMaker) => (
						<li key={decisionMaker.id} sx={{ mb: 8 }}>
							<LoadedDecisionMakerListItem
								key={decisionMaker.id}
								decisionMaker={decisionMaker}
								allDmResponses={allDmResponses}
							/>
						</li>
					))}
				</ul>
				{displayShowAllButton && (
					<FakeLink onClick={() => openModal()}>
						<Translate value="fe.components.notifications.see_all" />
					</FakeLink>
				)}
			</Box>
			<Separator mb={24} />
		</>
	);
}
