import { type JSX } from 'react';

import { Link } from '@change-corgi/design-system/components/actions';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconEmail } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useContactDM } from '../../../hooks/decisionMakers/useContactDM';

type ContactDmProps = {
	displayName: string;
	email?: string | null;
};

export function EmailDM({ email, displayName }: ContactDmProps): JSX.Element {
	const {
		data: { emailHref },
		actions: { handleEmailDmClick },
	} = useContactDM({ displayName, email });

	return (
		<>
			<Text size="caption" fontWeight="bold" as="p" mb={4}>
				Make your petition known to {displayName}
			</Text>
			{emailHref && (
				<Flex>
					<Flex sx={{ width: '50px', justifyContent: 'center' }} mr={10}>
						<Icon icon={iconEmail} />
					</Flex>
					<Text as="p" size="caption">
						<Link to={emailHref} variant="primary" onClick={handleEmailDmClick}>
							Send them an email
						</Link>
					</Text>
				</Flex>
			)}
		</>
	);
}
