import { usePetitionDetailsPageData, usePetitionDetailsPageFcm } from 'src/app/pages/petition/details/pageContext';
import type { DecisionMaker } from 'src/app/pages/petition/details/shared/types';

import { useDecisionMakersModal } from '../DecisionMakersModal';

export type AllDmResponses = ReadonlyArray<{
	readonly id: string;
	readonly decisionMaker: { readonly id: string; readonly displayName: string };
}>;

type Result = ModelHookResult<
	{
		displayed: boolean;
		displayedDMs: DecisionMaker[];
		displayShowAllButton: boolean;
		allDmResponses: AllDmResponses;
		shouldTrackDmEmailCtaView: boolean;
	},
	{
		openModal: () => void;
	}
>;

const DISPLAYED_DM_COUNT = 5;

export function useDecisionMakers(): Result {
	const pageData = usePetitionDetailsPageData();
	const fcm = usePetitionDetailsPageFcm();
	const openModal = useDecisionMakersModal();

	const {
		data: {
			petition: { primaryDecisionMakerConnection, dmResponsesConnection },
		},
	} = pageData;

	const displayedDMs = primaryDecisionMakerConnection.nodes.slice(0, DISPLAYED_DM_COUNT);

	return {
		data: {
			displayed: !!displayedDMs.length,
			displayedDMs,
			displayShowAllButton: primaryDecisionMakerConnection.totalCount > displayedDMs.length,
			allDmResponses: dmResponsesConnection.nodes,
			shouldTrackDmEmailCtaView: !!displayedDMs.find((dm) => dm.email),
		},
		actions: { openModal: () => openModal({ pageData, fcm }) },
	};
}
