import type { JSX } from 'react';

import { Separator } from '@change-corgi/design-system/components/content';
import { Box } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

import { ScrollTrackingWaypointContainer } from '../../../ScrollTrackingWaypoint';
import { useMediaHitsContext } from '../../shared/provider';
import { AddMediaHit } from '../AddMediaHit';
import { useShowAddMediaHit } from '../AddMediaHit/hooks/useShowAddMediaHit';
import { MediaHitList } from '../MediaHitList';

import { useMediaHits } from './hooks/useMediaHits';

export function MediaHitsWrapper(): JSX.Element | null {
	const {
		data: { petitionId, isStaffOrStarter },
	} = useMediaHits();

	const {
		data: { mediaEmbeds },
	} = useMediaHitsContext();

	const showAddMediaHit = useShowAddMediaHit();

	// Filter mediaEmbeds for media hits rendered properly
	const validMediaEmbeds = mediaEmbeds?.filter((mediaRendered) => mediaRendered.valid) || [];

	if (!isStaffOrStarter && validMediaEmbeds.length === 0) {
		return null;
	} // regular user, no media hits

	if (isStaffOrStarter && !showAddMediaHit && mediaEmbeds.length === 0) {
		return null;
	} // starter, falls out of FCM range, no media yet

	return (
		<Box px={[16, 0]} data-testid="media-hits-wrapper">
			<Heading as="h2" size={['h3']} mb={16}>
				Media Mentions
			</Heading>
			<ScrollTrackingWaypointContainer name="campaign_media_hits_view" trackingData={{ petition_id: petitionId }} />
			<AddMediaHit />
			<MediaHitList />
			<Separator role="presentation" my={24} />
		</Box>
	);
}
