import { useTracking } from '@change-corgi/core/react/tracking';

import { usePetitionDetailsPageData } from 'src/app/pages/petition/details/pageContext';
import { useCurrentUserIdAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';
import { craftSupporterEmail } from 'src/app/shared/utils/decisionMaker';

type Result = ModelHookResult<
	{ emailHref: string; showContactCTA: boolean },
	{
		handleEmailDmClick: () => void;
	}
>;

type UseContactDmArgs = {
	displayName: string;
	email?: string | null;
};

export function useContactDM({ email, displayName }: UseContactDmArgs): Result {
	const {
		data: {
			petition: {
				slug,
				ask,
				signatureState: {
					signatureCount: { displayedLocalized: signatureCount },
				},
			},
			emailDm,
		},
	} = usePetitionDetailsPageData();

	const currentUserState = useCurrentUserIdAsync();
	const viewerId = isLoaded(currentUserState) ? currentUserState.value : undefined;

	const track = useTracking();

	const handleEmailDmClick = () => {
		void track('petition_page_email_dm_click', {
			user_id: viewerId,
		});
	};

	const emailHref = craftSupporterEmail({
		dmEmail: email,
		dmName: displayName,
		ask,
		slug,
		signatureCount,
		emailDm,
	});

	const showContactCTA = !!email;

	return {
		data: {
			emailHref,
			showContactCTA,
		},
		actions: {
			handleEmailDmClick,
		},
	};
}
